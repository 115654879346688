<template>

  <!-- id : contents : (S) -->
  <div style="display:flex;width:100%">

    <div class="main-body-box">
      <div class="my-tit-box">1:1 문의 내역</div>
      <div class="board-search-wrap st3 st-message">
        <div class="tit-box mb-right"><a href="javascript:;" @click="onClickDelete" class="btn-mod-2 ">선택 삭제</a></div>
        <div class="search-box pc-only">
            <select v-model="searchColumn" class="input-st1 st-selectbox wd150" style="height: 40px;line-height: 38px;">
              <option value="title">제목</option>
              <option value="contents">내용</option>
            </select>
            <input type="text" v-model="searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd280 dg-ml-5 mb-wd100" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
            <a href="javascript:;" @click="onClickSearch" class="btn-s dg-ml-5 mb-wd100" style="height: 40px;line-height: 38px;">검색</a>
        </div>
      </div>
      <div class="tb-01 st2 st-over mb20">
        <table>
          <colgroup><col style="width:70px"><col style="width:70px"><col style="width:110px"><col style="width:*"><col style="width:120px"><col style="width:100px"></colgroup>
          <thead>
          <tr>
            <th>
              <input type="checkbox" class="chk-st1 st-none" id="chkall" v-model="checkAll" @change="onClickCheckAll"><label for="chkall">전체선택</label>
            </th>
            <th>번호</th>
            <th>구분</th>
            <th>제목</th>
            <th>작성일</th>
            <th>상태</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(content,idx) in contents.content" :key="content.articleId"   class="st-board">
            <td class="al-center pc-only">
              <input type="checkbox" v-model="selectCheckBox" :value="content" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label>
            </td>
            <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
            <td class="al-center pc-only">{{ content.categoryName }}</td>
            <td class=" pc-only ">
              <a href="javascript:;" @click="onClickTitle(content.articleId)" class="ellipsis_inline wd560">{{ content.title }}</a>
              <div class="tit-icon" style="padding-left: 7px">
                <span class="cmt" v-if="content.commentCount > 0">[{{ content.commentCount }}]</span>
              </div>
            </td>
            <td class="al-center pc-only">{{ formatDate(content.createdAt) }}</td>
            <td class="al-center pc-only">{{ content.commentCount > 0? '답변완료':'답변중' }}</td>
            <td class="mb-only">
              <div class="tb-top">
                <div class="tb-notice"><input type="checkbox" v-model="selectCheckBox" :value="content" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label></div>  
                <div class="tb-title">
                  <a href="javascript:;" @click="onClickTitle(content.articleId)" class="ellipsis_inline wd560">{{ content.title }}</a>
                  <div class="tit-icon" style="padding-left: 7px">
                    <span class="cmt" v-if="content.commentCount > 0">[{{ content.commentCount }}]</span>
                  </div>
                </div>
              </div>
              <div class="tb-btm">
                <div class="send-msg-menu-box tb-name"  ><!-- open 클래스 추가 시 버튼 노출 -->
                  {{ content.categoryName }}
                </div> 
                <div class="tb-date">
                  {{ formatDate(content.createdAt) }} 
                </div>
                <div class="tb-cmt">
                  {{ content.commentCount > 0? '답변완료':'답변중' }}
                </div> 
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="board-btn-box">
        <a href="javascript:;" @click="$emit('click:changeSt','write')">1:1 문의하기</a>
      </div>
      <!-- class : pagination (S) -->
      <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
      <!-- class : pagination (E) -->
    </div>

  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import DgPagination from "@/views/component/DgPagination";
import moment from "moment";
import userApi from "@/api/UserApi";
import articleApi from "@/api/ArticleApi";

export default {
  name: "InquiryList",
  components:{
    DgPagination
  },
  created(){
    this.getUserDetail();
    this.onClickSearch();
  },
  props:{
    boardId:{
      type:Number
    },
    reload:{
      type:Boolean
    }
  },
  data(){
    return {
      userId: this.$store.state.user.userid,

      contents:[],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      selectCheckBox:[],
      checkAll:false,

      searchColumn:'title',
      searchKeyword:''
    }
  },
  watch:{
    selectCheckBox(){
      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.selectCheckBox.length == checkSize && checkSize != 0){
        this.checkAll = true
      }
    },
    reload(e){
      if(e) {
        this.pagination= {
          currentPage: 1,
          pageSize: 10,
          pageGroupSize: 10,
          totalPages: 0,
          totalElements: 0
        },

        this.selectCheckBox=[],
        this.checkAll=false,

        this.searchColumn='title',
        this.searchKeyword='',

        this.onClickSearch();
        this.$emit('click:refreshReload', false);
      }
    },
  },
  methods:{
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    getUserDetail() {
      userApi.get(this.userId).then((data) => {
        this.userDetail = data
        this.userDetail.userHasGroups = []
        if (data.clubGroup != null) {
          if (data.clubGroup.length > 0) {
            for (let i = 0; i < data.clubGroup.length; i++) {
              this.userDetail.userHasGroups.push(data.clubGroup[i].groupId);

              this.userDetailClubs += data.clubGroup[i].groupName
              if (i < data.clubGroup.length - 1) {
                this.userDetailClubs += ", "
              }
            }
          }
        }
        this.userDetail.userHasGroups.push(data.departmentGroup[0].groupId);
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    onClickSearch(){
      let params = {
        size: this.pagination.pageSize,
        page: this.pagination.currentPage,
        searchColumn: this.searchColumn,
        searchKeyword: this.searchKeyword
      };
      articleApi.getMyArticlesFromBoard(this.boardId, this.userId, params).then((data)=>{
        this.contents = data;

        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.checkAll=false;
      this.selectCheckBox=[];
      this.onClickSearch();
    },
    onClickTitle(articleId){
      this.$emit('click:changeSt','detail', articleId)
    },
    onClickCheckAll(){
      if(this.checkAll){
        this.contents.content.forEach(data=>{
          this.selectCheckBox.push(data)
        })
      }else{
        this.selectCheckBox = []
      }
    },
    onClickCheckBox(){
      if(this.selectCheckBox.length == this.pagination.pageSize){
          this.checkAll=true
      }else{
        this.checkAll=false
      }
    },
    onClickDelete(){
      if(this.selectCheckBox.length==0){
        return alert('한 개 이상의 게시글을 선택해주세요.')
      }else{
        if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다. \n삭제하시겠습니까?')) {
          this.selectCheckBox.forEach(data => {
            articleApi.delete(data.boardId, data.articleId).then(()=>{
              this.pagination.currentPage = 1;
              this.checkAll=false;
              this.selectCheckBox=[];
              this.onClickSearch();
            })
          })
        }
      }
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickSearch();
      }
    },
  }
}
</script>

<style scoped>

</style>