<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >1:1 문의 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;"><router-link to="/my/myInfo">회원정보</router-link> </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
              </div>
              <div class="name-box">
                <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <a href="javascript:;" @click="onClickMenu">1:1 문의</a>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li class="on"><a href="javascript:;" @click="onClickMenu">활동 내역</a>
                    <ul>
                      <li class="on"><a href="javascript:;" @click="onClickMenu">1:1 문의</a></li>
                      <li><router-link to="/my/board">내 게시글</router-link></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>


            <InquiryList v-if="status=='list'" :boardId=boardId @click:changeSt="changeStatus"
                         :reload="reload" @click:refreshReload="refreshReload"></InquiryList>
            <InquiryDetail v-if="status=='detail'" :boardId=boardId :articleId="articleId" @click:changeSt="changeStatus"></InquiryDetail>
            <InquiryWrite v-if="status=='write'" :boardId=boardId @click:changeSt="changeStatus"></InquiryWrite>
            <InquiryCompletion v-if="status=='complete'" :boardId=boardId @click:changeSt="changeStatus"></InquiryCompletion>



          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import InquiryList from '@/views/mypage/inquiry/inquiry_list'
import InquiryDetail from '@/views/mypage/inquiry/inquiry_detail'
import InquiryWrite from '@/views/mypage/inquiry/inquiry_write'
import InquiryCompletion from '@/views/mypage/inquiry/inquiry_completion'
import userApi from "@/api/UserApi";

export default {
  name: "InquiryIndex",
  components:{
    InquiryList,
    InquiryDetail,
    InquiryWrite,
    InquiryCompletion
  }, 
  created() {
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail()
    }
  },
  data(){
    return {
      status: 'list',
      articleId: null,

      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      boardId: 1748,
      reload: false,
      tablistOpen : false,
    }
  },
  methods:{
    changeStatus(e, articleId){
      this.status = e;
      this.articleId = articleId;
    },
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickMenu(){
      this.reload = true;
      this.status = 'list';
      this.$router.push(`/my/inquiry`).catch(()=>{});
    },
    refreshReload(e){
      this.reload = e;
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  }
}
</script>

<style scoped>

</style>