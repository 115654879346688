<template>

  <!-- id : contents : (S) -->
  <div id='contents'>
    <div class="main-body-box">
      <div class="my-tit-box st-line">1:1 문의 하기</div>
      <div class="complete-box">
        <div class="txt1">1:1 문의 신청이 완료되었습니다.</div>
        <div class="txt2">관리자에서 확인 후 답변을 도와드리도록 하겠습니다. 감사합니다.</div>
        <div class="board-btn-box al-center">
          <a href="javascript:;" @click="$emit('click:changeSt','list',null)">내 문의 내역</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InquiryCompletion",
  props:{
    boardId:{
      Type:Number
    }
  }
}
</script>

<style scoped>

</style>