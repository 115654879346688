<template>

  <!-- id : contents : (S) -->
  <div id='contents'>

    <div class="main-body-box">
      <div class="my-tit-box">1:1 문의 내역</div>
      <div class="tb-01 st-replay">
        <table>
          <colgroup><col width="130px"><col width="*"></colgroup>
          <tbody>
          <tr>
            <th>구분</th>
            <td>{{ categoryName }}</td>
          </tr>
          <tr>
            <th>제목</th>
            <td>{{ article.title }}</td>
          </tr>
          <tr>
            <th>문의내용</th>
            <td>
              <div v-html="article.contents" v-if="!article.mobile"></div>
              <div v-html="article.contents.replace(/(?:\r\n|\r|\n)/g, '<br />')" v-else></div>
            </td>
          </tr>
          <tr>
            <th>파일첨부</th>
            <td>
              <div v-if="article.articleAttachments.length>0" class="attach-list-st2-box">
                <ul>
                  <li v-for="attach in article.articleAttachments" :key="attach.attachId">
                    <a :href="`${apiEndPoint}/board/${article.boardId}/article/${article.articleId}/file/${attach.fileId}`">{{ attach.fileRealName }}</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="tb-replay-tit-box">답변내용</div>
      <div class="tb-01 st-replay mb30">
        <table>
          <colgroup><col width="130px"><col width="*"></colgroup>
          <tbody v-for="(cmt, i) in comments" :key="`cmt-${i}`">
          <tr>
            <th>답변일</th>
            <td>{{ cmt.updatedAt!=null?formatDate(cmt.updatedAt):'' }}</td>
          </tr>
          <tr>
            <th>답변내용</th>
            <td><div v-html="cmt.contents"></div></td>
          </tr>
<!--          <tr>-->
<!--            <th>파일첨부</th>-->
<!--            <td>-->
<!--            </td>-->
<!--          </tr>-->
          </tbody>
        </table>
      </div>
      <div class="board-btn-box al-center st-two">
        <a href="javascript:;" class="st2" v-if="commentsTotalElement==0" @click="onClickDelete">문의 삭제</a>
        <a href="javascript:;" @click="$emit('click:changeSt','list',null)">목록가기</a>
      </div>
    </div>
  </div>

</template>

<script>
import articleApi from "@/api/ArticleApi";
import commentApi from "@/api/CommentApi";
import moment from "moment";
import boardApi from "@/api/BoardApi";

export default {
  name: "InquiryDetail",
  props:{
    boardId:{
      Type:Number
    },
    articleId:{
      Type:Number
    }
  },
  data(){
    return{
      article:{},
      comments:[],
      commentsTotalElement:0,
      apiEndPoint:process.env.VUE_APP_BASE_API,

      categoryName:''
    }
  },
  created(){
    articleApi.get(this.boardId, this.articleId).then(data => {
      this.article = data;

      boardApi.get(this.boardId).then(d => {
        d.boardCategories.forEach(c=>{
          if(c.categoryId==this.article.categoryId){
            this.categoryName = c.categoryName
          }
        });
      });
    });

    this.getComment();
  },
  methods:{
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    getArticle(){

    },
    getComment(){
      let params = {
        size: -1,
      };
      commentApi.list(this.boardId, this.articleId, params).then(data => {
        this.comments = data.content;
        this.commentsTotalElement = data.totalElements;
        if(this.commentsTotalElement==0){
          this.comments = [{'contents':'빠른 시일 내에 답변을 드리도록 하겠습니다. 조금만 더 기다려주세요.'}]
        }
      });
    },
    onClickDelete() {
      if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다. \n삭제하시겠습니까?')) {
        articleApi.delete(this.boardId, this.articleId).then(() => {
          this.$emit('click:changeSt', 'list', null)
        })
      }
    }

  }
}
</script>

<style scoped>

</style>