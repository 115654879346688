<template>

  <!-- id : contents : (S) -->
  <div id='contents'>
    <div class="main-body-box">
      <div class="my-tit-box">1:1 문의 하기</div>
      <div class="tb-01 st-replay">
        <table>
          <colgroup><col width="130px"><col width="*"></colgroup>
          <tbody>
          <tr>
            <th>구분</th>
            <td>
              <select name="" class="input-st1 st-selectbox wd200" v-model="article.categoryId">
<!--                <option value="">카테고리 선택</option>-->
                <option v-for="category in boardDetail.boardCategories" :key="category.categoryId" :value="category.categoryId">{{category.categoryName}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>제목</th>
            <td><input type="text" class="input-st1 wd100per" placeholder="제목을 입력하세요." v-model="article.title"></td>
          </tr>
          <tr>
            <th>문의내용</th>
            <td>
              <editor-component style="padding-left: 30px; padding-right: 30px;"
                  ref="contents"
                  :val="article.contents"
                  :uploaded="fileMeta"
                  @callback-upload="uploadImage"
                  label="게시판 내용" />
            </td>
          </tr>
          <tr v-if="boardDetail.hasAttachment" style="border-top: 1px solid #eeeeee;">
            <th>첨부파일</th>
            <td>
              <v-col class="mgr" style="padding: 0 0" >
                <v-file-input style="padding-top: 0px;margin-top: 10px;width: 95%"
                              v-model="files"
                              label="파일 선택"
                              multiple
                              prepend-icon="mdi-paperclip"
                              @change="appendFile"
                              @click:clear="clearAllFile"
                >
                  <template v-slot:selection="{ text, file }">
                    <v-chip
                        small
                        label
                        color="primary"
                        close @click:close="removeFile(file)"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>

                <div v-if="article.articleAttachments && this.article.articleAttachments.length>0">
                  <h4>첨부 완료 파일</h4>
                  <v-chip v-for="f in attachedFiles" v-bind:key="f.fileId" class="ma-2" close @click:close="removeAttachedFile(f)">
                    {{ f.fileRealName }}
                  </v-chip>
                  <div class="btnStyleBx">
                    <div class="btnCommon justify-center">
                      <v-btn class="vBtn" color="gray" dark @click="deleteAttachedFiles">
                        <v-icon left>mdi mdi-delete</v-icon>
                        첨부 완료 파일 전체 삭제
                      </v-btn>

                      <v-btn class="vBtn" color="#fafafa" @click="resetAttachedFiles">
                        <v-icon left>mdi mdi-reload</v-icon>
                        취소
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- tb-01 (E) -->
      </div>
      <div class="board-btn-box al-center st-two">
        <a href="javascript:;" class="st2" @click="$emit('click:changeSt','list')">내 문의 내역</a>
        <a href="javascript:;" @click="onClickSave">문의접수</a>
      </div>
    </div>

  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import boardApi from "@/api/BoardApi";
import articleApi from "@/api/ArticleApi";
import _ from 'lodash';
import EditorComponent from "@/views/component/EditorComponent";

export default {
  name: "inquiry_write",
  props:{
    boardId:{
      Type:Number
    }
  },
  components:{
    'editor-component': EditorComponent,
  },
  data() {
    return {
      boardDetail:{},

      article: {
        categoryId: null,
        articleId: null,
        title: null,
        contents: null,
        writer: null,
        always: false,
        startDt: null,
        endDt: null,
        thumbnail:null
      },

      fileMeta:{},
      files:[],
      selectedFiles:[],
      attachedFiles:[],
    }
  },
  created(){
    this.getCategory();
  },
  methods:{
    getCategory(){
      boardApi.get(this.boardId).then(data => {
        this.boardDetail = data;
        this.article.categoryId = data.boardCategories[0].categoryId;
      });
    },
    appendFile() {
      this.files = [...new Set([
        ...this.selectedFiles,
        ...this.files
      ])]
      this.selectedFiles = _.cloneDeep(this.files);
    },
    clearAllFile() {
      this.files = [];
      this.selectedFiles = [];
    },
    removeFile(removeFile) {
      this.files = this.files.filter(f => f !== removeFile);
      this.selectedFiles = _.cloneDeep(this.files);
    },
    removeAttachedFile(attachedFile) {
      this.attachedFiles = this.attachedFiles.filter(f => f.fileId !== attachedFile.fileId);
    },
    resetAttachedFiles() {
      this.attachedFiles = this.article.articleAttachments;
    },
    deleteAttachedFiles() {
      this.attachedFiles = []
    },
    uploadImage(f) {
      articleApi.uploadImage(this.boardId, f).then(res => {
        // console.log(res);
        this.fileMeta = res;
        console.log('//////////'+JSON.stringify(res))
      });
    },
    async onClickSave() {
      this.article.boardId = this.boardId;
      this.article.contents = this.$refs.contents.getValue();
      this.article.writer= this.$store.state?.user?.username??'';
      this.article.createdBy= this.$store.state?.user?.userid??'';

      this.article.articleAttachments = this.attachedFiles;
      articleApi.save(this.article, this.files).then(data => {
        if (data) {
          if(this.article.articleId) {
            alert("수정되었습니다.");
            this.$emit('click:title','detail',this.articleId)
          } else {
            let boardType = this.boardDetail.boardType=='TYPE_GENERAL'?'list':'photo'
            this.$emit('click:title',boardType,this.articleId)
          }

          this.$emit('click:changeSt','complete',null)
        } else {
          alert("Error");
        }
      });
    },
    onClickCancel(){
      if(confirm('작성을 정말 취소하시겠습니까? \n작성한 내용이 저장되지 않습니다.')){
        if(this.article.articleId) {
          this.$emit('click:title','detail',this.articleId)
          //this.$router.push({path: `/board/${this.currentTabMenu.boardId}/${this.article.articleId}?upper=${this.parentMenu}`})
        } else {
          let boardType = this.boardDetail.boardType=='TYPE_GENERAL'?'list':'photo'
          this.$emit('click:title',boardType,this.articleId)
          //this.$router.push({path: `/board/${this.currentTabMenu.boardId}?upper=${this.parentMenu}`})
        }
      }
    },
    onClickSettings(e){
      if(e.target.id === 'Always' && !this.article.always){
        this.setPeriod = false
      }else if(e.target.id === 'Period' && this.setPeriod) {
        this.article.always = true
      }
    },

  }
}
</script>

<style scoped> 
</style>